<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="题集标题：">
              <el-input
                v-model="queryParam.className"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="题集状态：">
              <el-select
                v-model="queryParam.status"
                placeholder="请选择题集状态"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="24">
            <el-form-item label="题集日期：">
              <date-picker
                ref="dataPicker"
                :dateTime="dateTime"
                class="w100"
                @updateView="datetTimeGet"
              ></date-picker>
            </el-form-item>
          </el-col>
          <search-query
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="false"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <div style="height: calc(100vh - 40px); overflow-y: auto">
      <!-- 表格 -->
      <el-card
        shadow="never"
        style="overflow-y: scroll; height: calc(100% - 80px)"
      >
        <div slot="header" class="flex" style="align-items: center">
          <el-button
            type="primary"
            style="margin-top: 10px"
            size="mini"
            @click="addquesTemplate"
            >新增模版
          </el-button>
          <div class="flex_1"></div>

          <text-button
            icon="el-icon-refresh"
            contentTit="刷新"
            @click.native="search"
          ></text-button>
        </div>

        <el-table :data="tableData" fit :highlight-current-row="true">
          <el-table-column prop="title" label="标题" width="180">
          </el-table-column>
          <el-table-column prop="name" label="创建时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.createTime.substring(0, 19) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="所选题目" width="250">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.questionList" :key="index">
                {{ item.problemTitle }}({{ item.problemId }}){{
                  index < item.length - 1 ? ", " : ""
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.status == '已启用'"
                type="success"
                effect="dark"
              >
                {{ scope.row.status }}
              </el-tag>
              <el-tag
                v-if="scope.row.status == '已禁用'"
                type="danger"
                effect="dark"
              >
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click.native="editContent(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click.native="delContent(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          size="default"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.pageNum"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="pageInfo.pageSize"
          :total="pageInfo.total"
          layout="total, sizes, prev, pager, next, jumper"
          background
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import datePicker from "@/components/date-picker";
import {
  getContestLibraryList,
  deleteContestLibrary,
} from "@/api/research/curriculum";
export default {
  components: { TextButton, SearchQuery, datePicker },
  data() {
    return {
      tableData: [],
      queryParam: {
        title: "",
        status: "",
      },
      startTime: "",
      endTime: "",
      dateTime: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      statusOptions: [
        {
          value: "已启用",
          label: "已启用",
        },
        {
          value: "已禁用",
          label: "已禁用",
        },
        {
          value: "已删除",
          label: "已删除",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    editContent(row) {
      let questionList = row.questionList.map((item) => {
        const { problemId, ...rest } = item;
        return rest;
      });
      this.$router.push({
        path: "/operation/operationDetail",
        query: {
          row: JSON.stringify({ ...row, questionList }),
        },
      });
    },
    async delContent(row) {
      const confirmResult = await this.$confirm("确认删除该模板？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return;
      }
      let { state } = await deleteContestLibrary({
        libraryId: row.id,
      });
      if (state == "success") {
        $msg("删除成功！");
        this.getTableData();
      }
    },
    addquesTemplate() {
      this.$router.push({
        path: "/operation/operationDetail",
        query: {},
      });
    },
    search() {
      this.pageInfo.pageNum = 1;
      this.getTableData();
    },
    async getTableData() {
      let {
        state,
        body: { list, total },
      } = await getContestLibraryList({
        ...this.queryParam,
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
      });
      if (state == "success") {
        this.tableData = list;
        this.pageInfo.total = total;
      }
    },
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getTableData();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getTableData();
    },
    datetTimeGet(data) {
      if (data === null) {
        this.startTime = "";
        this.endTime = "";
      } else {
        this.startTime = data[0];
        this.endTime = data[1].substring(0, 10) + " 23:59:59";
      }
    },
    // 清空选项
    clearQuery() {
      this.queryParam = {
        title: "",
        status: "",
      };
      this.$refs.dataPicker.time = [];
      this.endTime = "";
      this.startTime = "";
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
